export default {
  name: "display-res",
  components: {},
  props: ["trovati", "strSrc"],
  watch: {
    strSrc(valNew) {
      this.strsrc = valNew;
    },
  },
  data() {
    return {
      strsrc: this.strSrc,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    autNomCogDat(i) {
      var k1 = Object.keys(i)[0];
      var k2 = Object.keys(i[k1])[0];
      var k3 = Object.keys(i[k1][k2])[0];
      var tmp = i[k1][k2][k3][0];
      return tmp.nome + " " + tmp.cognome + " [" + tmp.data + "]";
    },
    opera(i) {
      var k1 = Object.keys(i)[0];
      var k2 = Object.keys(i[k1])[0];
      var tmp = i[k1][k2][0];
      return tmp.titopera + " [" + tmp.annoopera + "]";
    },
    idFonte(i) {
      var k1 = Object.keys(i)[0];
      var tmp = i[k1][0];
      return tmp.id_fonte;
    },
    titoloFonte(i) {
      var k1 = Object.keys(i)[0];
      var tmp = i[k1][0];
      return tmp.titfonte;
    },
    esisteImgFonte(i) {
      var ret = false;
      var k1 = Object.keys(i)[0];
      var tmp = i[k1][0].id_fonte;
      if (this.$parent.fonti[tmp][0].img_fonte) {
        ret = true;
      }
      return ret;
    },
    urlFotoFonte(i) {
      var k1 = Object.keys(i)[0];
      var tmp = i[k1][0].id_fonte;
      var url = this.$parent.fonti[tmp][0].img_fonte;
      var ret = "/jpg/" + url;
      if (this.$parent.modeDev) {
        ret = "https://poesialirica.it/jpg/" + url;
      }
      return ret;
    },
    fonte(i) {
      var k1 = Object.keys(i)[0];
      var k2 = Object.keys(i[k1])[0];
      var tmp = i[k1][k2][0].id_fonte;
      return this.$parent.fonti[tmp][0].note_edit;
    },
    boldQuery(input, needle) {
      return input.replace(
        new RegExp("(^|)(" + needle + ")(|$)", "ig"),
        "###$2@@@"
      );
    },
    prepareComp(t, sr) {
      var tt = t;
      if (sr) {
        sr.split(/&&|\|\|/).forEach((x) => {
          x = x.replace("'", "’");
          if (
            this.$parent.opzRicerca.includes("omogr") &&
            this.$parent.omografi[x.trim()]
          ) {
            tt = this.boldQuery(tt, this.$parent.omografi[x.trim()]);
          } else {
            tt = this.boldQuery(tt, x.trim());
          }
        });
        tt = tt
          .replaceAll("###", "<span style='color: #00F; font-weight: bold;'>")
          .replaceAll("@@@", "</span>");
      }
      var tmp = tt.split("\r\n");
      var ret = "<table>";
      var nv = 0;
      tmp.forEach((x) => {
        if (x.trim() === "" || x[0] === "#") {
          ret +=
            "<tr><td>" +
            x.replaceAll("#", "") +
            "&nbsp;</td><td class='pl-5'></td></tr>";
        } else {
          nv++;
          ret +=
            "<tr><td>" +
            x +
            "&nbsp;</td><td class='pl-5'>" +
            (nv % 5 ? "" : nv) +
            "</td></tr>";
        }
      });
      ret += "</table>";
      return ret;
    },
    prepareCnote(t) {
      return t.replaceAll("<em>", '<em style="color: #00F;">');
    },
    romanize(num) {
      if (isNaN(num)) return NaN;
      var digits = String(+num).split(""),
        key = [
          "",
          "C",
          "CC",
          "CCC",
          "CD",
          "D",
          "DC",
          "DCC",
          "DCCC",
          "CM",
          "",
          "X",
          "XX",
          "XXX",
          "XL",
          "L",
          "LX",
          "LXX",
          "LXXX",
          "XC",
          "",
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
        ],
        roman = "",
        i = 3;
      while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
      return Array(+digits.join("") + 1).join("M") + roman;
    },
  },
};
